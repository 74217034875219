<template>
    <v-app>
        <x-background :src="require('@/assets/bg-video.webm')"/>
        <x-nav-bar @menu="mobileNav = true"/>
        <x-mobile-nav-menu v-model="mobileNav"/>

        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
import XNavBar from "@/components/XNavBar.vue";
import XBackground from "@/components/XBackground.vue";
import XMobileNavMenu from "@/components/XMobileNavMenu.vue";

export default {
    name: 'App',
    components: {XMobileNavMenu, XBackground, XNavBar},

    metaInfo: {
        titleTemplate: 'Xynodyne | %s',
    },

    data: () => ({
        mobileNav: false,
    }),
};
</script>
