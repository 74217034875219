<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "XMobileNavMenu",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
})
</script>

<template>
    <v-navigation-drawer dark fixed bottom :value="value" @input="$emit('input', $event)">
        <v-list nav>
            <v-list-item to="/home">
                <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
            <v-list-item to="/about">
                <v-list-item-title>Über uns</v-list-item-title>
            </v-list-item>
            <v-list-item to="/ranks">
                <v-list-item-title>Ränge</v-list-item-title>
            </v-list-item>
            <v-list-item to="/services">
                <v-list-item-title>Dienste</v-list-item-title>
            </v-list-item>
            <v-list-item to="/media">
                <v-list-item-title>Media</v-list-item-title>
            </v-list-item>
            <v-list-item to="/contact">
                <v-list-item-title>Kontakt</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<style scoped>

</style>