<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "XNavBar",
    data: function () {
        return {
            menu: false,
        };
    },
    watch: {
        '$route'() {
            this.$vuetify.goTo(0, {duration: 0});
        },
    },
})
</script>

<template>
    <v-app-bar
        app
        tile
        dark
    >
        <v-layout d-flex align-center justify-space-between v-if="$vuetify.breakpoint.smAndDown">
            <v-flex shrink>
                <v-img src="@/assets/logo.webp" width="56" height="56" contain/>
            </v-flex>

            <h1>XYNODYNE</h1>

            <v-btn icon @click="$emit('menu')">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
        </v-layout>

        <v-tabs
            fixed-tabs
            v-else
        >
            <v-tab to="/home">Home</v-tab>
            <v-tab to="/about">Über uns</v-tab>
            <v-tab to="/ranks">Ränge</v-tab>
            <v-tab disabled class="mx-5">
                <v-flex shrink class="mr-10">
                    <v-img src="@/assets/logo.webp" width="56" height="56" contain/>
                </v-flex>

                <v-flex>
                    <h1>XYNODYNE</h1>
                </v-flex>
            </v-tab>
            <v-tab to="/services">Dienste</v-tab>
            <v-tab to="/media">Media</v-tab>
            <v-tab to="/contact">Kontakt</v-tab>
        </v-tabs>
    </v-app-bar>
</template>

<style scoped>
/deep/ .v-tabs-bar {
    background-color: transparent !important;
}
.v-sheet {
    background-color: rgba(39,39,39,0.5) !important;
    backdrop-filter: blur(10px);
}
</style>