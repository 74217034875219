<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "XBackground",
    props: {
        src: {
            type: String,
            default: '',
        },
    },
})
</script>

<template>
    <video class="fullscreen" playsinline autoplay loop muted disablePictureInPicture>
        <source :src="src">
    </video>
</template>

<style scoped>
.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    pointer-events: none;
}
</style>